import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
    {
        path: "/",
        redirect: {
            name: "index"
        }
    },
    {
        path: "/index",
        name: "index",
        component: () => import("./views/index/index.vue")
    },
    
    {
        path: "/trading",
        name: "trading",
        component: () => import("./views/trading/index.vue")
    },
    {
        path: "/tradinghis",
        name: "tradinghis",
        component: () => import("./views/trading/allhis.vue")
    },
    {
        path: "/item",
        name: "item",
        component: () => import("./views/item/index.vue")
    },
    {
        path: "/item/:code",
        name: "itemDetail",
        component: () => import("./views/item/detail.vue")
    },
    {
        path: "/doinvest/:code",
        name: "doinvest",
        component: () => import("./views/item/buy.vue")
    },
    {
        path: "/activity",
        name: "activity",
        component: () => import("./views/activity/index.vue")
    },
    {
        path: "/activity/:code",
        name: "activityDetail",
        component: () => import("./views/activity/detail.vue")
    },
    {
        path: "/user",
        name: "user",
        component: () => import("./views/user/index.vue")
    },
    {
        path: "/pay",
        name: "pay",
        component: () => import("./views/user/pay.vue")
    },
    {
        path: "/account",
        name: "account",
        component: () => import("./views/user/account.vue")
    },
    {
        path: "/addcount",
        name: "addcount",
        component: () => import("./views/user/addcount.vue")
    },
    {
        path: "/addcountUsdt",
        name: "addcountUsdt",
        component: () => import("./views/user/addcountUsdt.vue")
    },
    {
        path: "/transfer",
        name: "transfer",
        component: () => import("./views/user/transfer.vue")
    },
    {
        path: "/moneybag",
        name: "moneybag",
        component: () => import("./views/user/moneybag.vue")
    },
    {
        path: "/rechargeRecord",
        name: "rechargeRecord",
        component: () => import("./views/user/rechargeRecord.vue")
    },
    {
        path: "/withdrawRecord",
        name: "withdrawRecord",
        component: () => import("./views/user/withdrawRecord.vue")
    },
    {
        path: "/login",
        name: "login",
        component: () => import("./views/user/login.vue")
    },
    {
        path: "/register",
        name: "register",
        component: () => import("./views/user/register.vue")
    },
    {
        path: "/forgetpwd",
        name: "forgetpwd",
        component: () => import("./views/user/forgetpwd.vue")
    },
    {
        path: "/invest2",
        name: "invest2",
        component: () => import("./views/user/invest2.vue")
    },
    {
        path: "/invest",
        name: "invest",
        component: () => import("./views/user/invest.vue")
    },
    {
        path: "/invest/trx/:code",
        name: "investtrx",
        component: () => import("./views/user/invest.vue")
    },
    {
        path: "/invest/wx",
        name: "wx",
        component: () => import("./views/user/invest_qrcode.vue")
    },
    {
        path: "/invest/usdt",
        name: "usdt",
        component: () => import("./views/user/invest_qrcode.vue")
    },
    {
        path: "/invest/bank",
        name: "bankpay",
        component: () => import("./views/user/invest_bank.vue")
    },
    {
        path: "/cash",
        name: "cash",
        component: () => import("./views/user/cash.vue")
    },
    {
        path: "/vip",
        name: "vip",
        component: () => import("./views/user/vip.vue")
    },
    {
        path: "/teamhis",
        name: "teamhis",
        component: () => import("./views/user/teamhis.vue")
    },
    {
        path: "/teamreg",
        name: "teamreg",
        component: () => import("./views/user/teamreg.vue")
    },
    {
        path: "/safe",
        name: "safe",
        component: () => import("./views/user/safe.vue")
    },
    {
        path: "/setpwd",
        name: "setpwd",
        component: () => import("./views/user/setpwd.vue")
    },
    {
        path: "/setpaypwd",
        name: "setpaypwd",
        component: () => import("./views/user/setpaypwd.vue")
    },
    {
        path: "/order",
        name: "order",
        component: () => import("./views/user/order.vue")
    },
    {
        path: "/funds",
        name: "funds",
        component: () => import("./views/user/funds.vue")
    },
    {
        path: "/myTeam",
        name: "myTeam",
        component: () => import("./views/user/myTeam.vue")
    },
    {
        path: "/notice",
        name: "notice",
        component: () => import("./views/user/notice.vue")
    },
    {
        path: "/notice/:id",
        name: "noticeid",
        component: () => import("./views/user/notice_article.vue")
    },
    {
        path: "/agreement/:id",
        name: "agreement",
        component: () => import("./views/user/agreement.vue")
    },
    {
        path: "/about",
        name: "about",
        component: () => import("./views/user/about.vue")
    },
    {
        path: "/about/:code",
        name: "article",
        component: () => import("./views/user/article.vue")
    },
    {
        path: "/search",
        name: "search",
        component: () => import("./views/item/search.vue")
    },
    {
        path: "/kefu",
        name: "kefu",
        component: () => import("./views/user/kefu.vue")
    },
    {
        path: "/luckDraw",
        name: "luckDraw",
        component: () => import("./views/user/luckDraw.vue")
    },
    {
        path: "/authEmail",
        name: "authEmail",
        component: () => import("./views/user/authEmail.vue")
    },
    {
        path: "/language",
        name: "language",
        component: () => import("./views/user/language.vue")
    },
    {
        path: "/aboutus",
        name: "aboutus",
        component: () => import("./views/index/about.vue")
    },
    {
        path: "/video",
        name: "video",
        component: () => import("./views/video/video.vue")
    }
];

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
    routes
});

export default router;
