

import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/vue'
import { mainnet, polygon } from 'viem/chains'
import { ref, onUnmounted } from 'vue';
import Fetch from "./fetch"
import router from '@/router'
import { 
	getAccount,
	getChainId,
	reconnect,
	watchClient,
	watchAccount,
	watchChainId,
	createStorage} from '@wagmi/core'
// 1. Define constants
const projectId = 'a5825827a80f6c5cc3059bb2b21c5417'

// 2. Create wagmiConfig
const metadata = {
  name: 'Web3Modal',
  description: 'Web3Modal Example',
  url: 'https://web3modal.com', // origin must match your domain & subdomain
  icons: ['https://avatars.githubusercontent.com/u/37784886']
}

const chains = [polygon]
export const wagmiConfig = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
  storage: createStorage({ storage: window.localStorage }),
  enableCoinbase: false,
  enableInjected: false,
  auth: {
	  email: false, // default to true
	  // socials: ['google', 'x', 'github', 'discord', 'apple', 'facebook', 'farcaster'],
	  showWallets: true, 
	  walletFeatures: true 
	}
  // ...wagmiOptions // Optional - Override createConfig parameters
})

reconnect(wagmiConfig)
// 3. Create modal
createWeb3Modal({
	wagmiConfig: wagmiConfig,
	projectId,
	themeMode: 'dark',
	enableOnramp: true,
	enableSwaps: true,
	enableAnalytics: true  
})

import { useWeb3Modal } from '@web3modal/wagmi/vue'
			
export  function dologin() {
  const { open, close } = useWeb3Modal()
  open()
}

export function useAccount() {
  const loginAccount = ref(getAccount(wagmiConfig));

  const unwatch = watchAccount(wagmiConfig, { 
    onChange: (newAccount) => {
		console.log('newAccount',newAccount);
		if(newAccount && newAccount.address){
			if(newAccount.address != localStorage.getItem('userAddress')){
				localStorage.setItem('userAddress', newAccount.address);
				const t_mobile = localStorage.getItem('inviter');
				const agent = localStorage.getItem('agent');
				Fetch("/index/login", {
				    userAddress: newAccount.address,
				    agent: agent,
				    t_mobile: t_mobile
				}).then((res) => {
					if(res.data && res.data.token){
						localStorage.setItem('token', res.data.token);
						router.push('/index');
					}
				})
			}
			
		}else{
			// localStorage.removeItem('token');
			// localStorage.removeItem('userAddress');
			// router.push('/index');
		}
		loginAccount.value = newAccount;
    },
  });

  // Cleanup on unmount
  onUnmounted(() => {
    if (unwatch) {
      unwatch();
    }
  });

  return { loginAccount };
}